import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../components/Layout";
import CTASection from "../../components/CTASection";
import Author from "../../components/Author";
import { Helmet } from "react-helmet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Layout {...props} cta title="Why Bedrock uses Nexus to create its GraphQL schema" description="Nexus is a code-first TypeScript library to write GraphQL schemas. It's how I've written my GraphQL schemas for a year now, and I'm never going back to doing it another way!" image="https://bedrock.mxstbr.com/nexus-meta.png" />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div align="center" style={{
      marginTop: `2em`
    }}>
      <h1>{`Why Bedrock uses Nexus to create its GraphQL schema`}</h1>
      <Author mdxType="Author" />
    </div>
    <p><a parentName="p" {...{
        "href": "https://nexusjs.org"
      }}><strong parentName="a">{`Nexus`}</strong></a>{` is a code-first TypeScript library to write GraphQL schemas. It's how I've written my GraphQL schemas for a year now, and I'm never going back. Four reasons:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Incredible TypeScript support`}</strong>{`: I just start typing and Nexus auto-completes me! Not only that, but I don't have to manually annotate a single resolver and they're still fully type-safe.`}</li>
      <li parentName="ul"><strong parentName="li">{`Single source of truth`}</strong>{`: Having a schema and a separate list of resolvers that I have to manually match is prone to bugs and impossible to do type-safely. With Nexus, I have a single source of truth for both.`}</li>
      <li parentName="ul"><strong parentName="li">{`Prisma integration`}</strong>{`: The `}<a parentName="li" {...{
          "href": "/tools/prisma"
        }}>{`Prisma`}</a>{` plugin means I can quickly expose database fields without having to write resolvers for every single thing. That saves me time `}<em parentName="li">{`and`}</em>{` is more type-safe. Win-win!`}</li>
      <li parentName="ul"><strong parentName="li">{`Active maintenance & community`}</strong>{`: Just like Prisma, Nexus is actively maintained and keeps getting better and better. The community is also very engaged, making it easy to get answers to any question you might have.`}</li>
    </ul>
    <p>{`All in all, I would highly recommend using Nexus for your next project, which is why Bedrock comes pre-configured with Nexus out of the box. However, it lets you write your GraphQL schema a different way if you prefer, of course!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      